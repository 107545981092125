exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tutorials-index-js": () => import("./../../../src/pages/tutorials/index.js" /* webpackChunkName: "component---src-pages-tutorials-index-js" */),
  "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-certificate-mdx": () => import("./../../../src/pages/tutorials/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tutorials/certificate.mdx" /* webpackChunkName: "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-certificate-mdx" */),
  "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-docker-mdx": () => import("./../../../src/pages/tutorials/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tutorials/docker.mdx" /* webpackChunkName: "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-docker-mdx" */),
  "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-github-mdx": () => import("./../../../src/pages/tutorials/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tutorials/github.mdx" /* webpackChunkName: "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-github-mdx" */),
  "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-psql-nodes-mdx": () => import("./../../../src/pages/tutorials/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tutorials/psql_nodes.mdx" /* webpackChunkName: "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-psql-nodes-mdx" */),
  "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-terminal-mdx": () => import("./../../../src/pages/tutorials/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tutorials/terminal.mdx" /* webpackChunkName: "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-terminal-mdx" */),
  "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-vm-mdx": () => import("./../../../src/pages/tutorials/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tutorials/vm.mdx" /* webpackChunkName: "component---src-pages-tutorials-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-tutorials-vm-mdx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

